header,
footer,
.bottom_banner {
  display: none;
}

div#mainHeader {
  display: none;
}

.comingsoon_content {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 600px;
}

.comingsoon_content .hero_banners {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 0;
  background-size: cover;
  background-position: left center;
  margin-left: 600px;
  transition: opacity 1s;
}

.comingsoon_content .hero_banner,
.carousel-slide {
  height: 100% !important;
  width: 100%;
  background-size: cover;
  background-position: left center;
}

#iframe {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  padding-left: 600px;
}

#iframe iframe {
  height: 100%;
  width: 100%;
}

.hero_banner.active {
  opacity: 1;
}

.comingsoon_content .sidebar {
  position: relative;
  left: 0;
  top: 0;
  z-index: 999;
  height: 100vh;
  min-height: 600px;
  width: 600px;
  background-color: white;
  box-shadow: 0px 0px 10px grey;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  transition: margin 0.5s;
  overflow: hidden;
  overflow-y: auto;
}

.comingsoon_content .sidebar.slide {
  margin-left: -600px;
}

.comingsoon_content .sidebar img {
  max-width: 100%;
}

.comingsoon_content .sidebar img.logo {
  width: 80%;
  max-width: 300px;
  margin-bottom: 40px;
}

.sidebar .text {
  padding: 75px;
  text-align: left;
}

.sidebar .text.landing {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sidebar h1 {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 25px;
  margin-top: 0;
}

.sidebar p {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 5px;
}

.times {
  margin-top: 10px;
}

.times p {
  font-size: 17px;
  opacity: 0.7;
}

.sidebar .button {
  margin-top: 30px;
}

.menus {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.menus .menu {
  background-color: #df1a25;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.menus p {
  width: 100%;
}

#register {
  max-width: 300px;
}

#register {
  background: transparent;
  color: #df1a25;
  text-decoration: underline;
  border: none;
  margin-top: 10px;
}

.register {
  display: none;
}

.register form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.register input {
  width: 48%;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  outline: none;
  padding: 25px 30px;
}

.register .full {
  width: 100%;
}

.register .check {
  display: flex;
  position: relative;
}

.register .check input {
  width: 25px;
  padding: 0;
  height: 25px;
  cursor: pointer;
}

.close {
  cursor: pointer;
  background-color: white;
  font-size: 25px;
  text-align: center;
  padding: 10px;
}

.check label {
  margin-top: 6px;
  margin-left: 10px;
}

.check img {
  height: 15px;
  position: absolute;
  left: 11px;
  top: 5px;
  cursor: pointer;
}

.check img {
  display: none;
}

.check img.show {
  display: block;
}

.question {
  margin: 30px 0;
  padding-top: 30px;
  border-top: 1px solid #eaeaea;
  width: 100%;
  margin-bottom: 0;
}

.question p {
  font-size: 19px;
  margin-bottom: 18px;
  font-weight: 600;
}

.socialMedia {
  margin-top: 30px;

  img {
    height: 35px;
    margin: 5px;
    cursor: pointer;
  }
}

/*=========================. RESPONSIVENESS. ============================*/
@media screen and (max-width: 1700px) {
  .sidebar h1 {
    font-size: 41px;
  }

  .comingsoon_content .sidebar img.logo {
    width: 80%;
    max-width: 250px;
    margin-bottom: 34px;
  }

  .sidebar p {
    font-size: 20px;
  }

  .sidebar .button {
    margin-top: 35px;
    font-size: 17px;
    padding: 17px 30px;
  }
}

@media screen and (max-width: 1300px) {
  .comingsoon_content .sidebar {
    width: 450px;
  }

  #iframe {
    padding-left: 450px;
  }

  .comingsoon_content .hero_banners {
    margin-left: 450px;
  }

  .comingsoon_content .sidebar.slide {
    margin-left: -450px;
  }

  .comingsoon_content .sidebar img.logo {
    width: 280px;
    margin-bottom: 28px;
  }
}

@media screen and (max-width: 1000px) {
  .comingsoon_content .sidebar {
    width: 100%;
    height: auto;
    min-height: 100%;
    position: absolute;
    background-color: transparent;
  }

  #iframe {
    padding: 0;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .comingsoon_content .hero_banners {
    margin-left: 0;
  }

  .comingsoon_content .sidebar.slide {
    margin-left: 1000px;
  }

  .text {
    background-color: white;
    width: 100%;
  }

  .sidebar h1 {
    font-size: 33px;
  }
}

@media screen and (max-width: 700px) {
  #iframe {
    padding: 0;
  }

  .sidebar .text {
    padding: 50px 30px;
  }

  .comingsoon_content .sidebar img.logo {
    width: 200px;
  }

  .sidebar h1 {
    font-size: 33px;
  }

  .sidebar p {
    font-size: 18px;
  }

  .sidebar .button {
    font-size: 15px;
  }

  .register input {
    width: 100%;
  }

  .check label {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

@media screen and (max-height: 500px) {
  .sidebar h1 {
    font-size: 30px;
  }
}