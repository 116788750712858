@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;600;700&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

.carousel-slide,
.carousel-slider,
.slider-wrapper,
.slider,
.slide {
  height: 100% !important;
  width: 100%;
  background-size: cover;
  background-position: left center;
}

.control-dots,
.control-arrow {
  display: none;
}

.button {
  background-color: #df1a25;
  border: none;
  outline: none;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
  font-size: 17px;
  padding: 15px 25px;
}

.fadeInAndOut {
  width: 100%;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  position: relative;
  &.fadein {
    opacity: 1;
    pointer-events: all;
    max-height: 10000px;
  }
  &.fadeOut {
    opacity: 0;
    pointer-events: none;
    max-height: 0;
    overflow: hidden;
  }
}
